
import { DataType } from './dataType';
import { FinancialsVendor } from './financialsVendor';
import { State } from './state';
import { Country } from './country';
import { User } from './user';

export class FinancialsOneTimeVendor extends DataType {
	id: number;
	financialsVendorId: number;
	vendor: FinancialsVendor;
	oneTimeVendorNumber: number;
	oneTimeName: string;
	oneTimeAddress1: string;
	oneTimeAddress2: string;
	oneTimeAddress3: string;
	oneTimeCity: string;
	oneTimeZipCode: string;
	oneTimeStateName: string;
	oneTimeStateId: number;
    oneTimeState: State;
	oneTimeCountryId: number;
    oneTimeCountry: Country;
	updatedByUserId: number;
	updatedByUser: User;
	createdAt: string;
    updatedAt: string;
	convertToVendorId: number;
	convertToVendor: FinancialsVendor

	constructor(object: Object = {}) {
		const properties = [
			'id',
			'financialsVendorId',
			'vendor',
			'oneTimeVendorNumber',
			'oneTimeName',
			'oneTimeAddress1',
			'oneTimeAddress2',
			'oneTimeAddress3',
			'oneTimeCity',
			'oneTimeZipCode',
			'oneTimeStateName',
			'oneTimeStateId',
			'oneTimeState',
			'oneTimeCountryId',
			'oneTimeCountry',
			'updatedByUserId',
			'updatedByUser',
			'createdAt',
			'updatedAt',
			'convertToVendorId'];
		super(object, properties);
	}
}
