import { DataType } from './dataType';
import { FinancialsFund } from './financialsFund';
import { FinancialsDepartmentSeg } from './financialsDepartmentSeg';
import { FinancialsDivisionSeg } from './financialsDivisionSeg';
import { FinancialsAccountObject } from './financialsAccountObject';
import { FinancialsProject } from './financialsProject';
import { FinancialsFunction } from './financialsFunction';
import { FinancialsAccount } from './financialsAccount';
import { FinancialsJournal } from './financialsJournal';
import { FinancialsPeriod } from './financialsPeriod';
import { FinancialsFiscalYear } from './financialsFiscalYear';
import { FinancialsInvoice } from './financialsInvoice';
import { FinancialsInvoiceLineItem } from './financialsInvoiceLineItem';
import { BankAccount } from './bankAccount';
import { FinancialsPayment } from './financialsPayment';
import { User } from './user';
import { FinancialsAccountCrossReference } from './financialsAccountCrossReference';
import { FinancialsProjectType } from './financialsProjectType';
import { FinancialsProjectGroup } from './financialsProjectGroup';

export class FinancialsProjectTransaction extends DataType {
	id: number;
	description: string;
	journalDesc: string;
	journalType: string;
	financialsAccountObjectId: number;
	accountObject: FinancialsAccountObject;
	financialsFundId: number;
	fund: FinancialsFund;
	financialsDepartmentSegId: number;
	departmentSeg: FinancialsDepartmentSeg;
	financialsDivisionSegId: number;
	divisionSeg: FinancialsDivisionSeg;
	financialsProjectId: number;
	project: FinancialsProject;
	financialsFunctionId: number;
	function: FinancialsFunction;
	financialsAccountId: number;
	account: FinancialsAccount;
	financialsJournalId: number;
	journal: FinancialsJournal;
	financialsInvoiceId: number;
	invoice: FinancialsInvoice;
	financialsInvoiceLineItemId: number;
	invoiceLineItem: FinancialsInvoiceLineItem;
	financialsPaymentId: number;
	payment: FinancialsPayment;
	paymentNumber: number;
	bankAccountId: number;
	bankAccount: BankAccount;
	fundVal: string;
	deptVal: string;
	divVal: string;
	objVal: string;
	projVal: string;
	funcVal: string;
	accountVal: string;
	journalVal: string;
	invoiceVal: number;
	invoiceLineItemVal: number;
	periodVal: number;
	financialsPeriodId: number;
	period: FinancialsPeriod;
	fyVal: number;
	financialsFiscalYearId: number;
	fiscalYear: FinancialsFiscalYear;
	effectiveDate: string;
	postedDate: string;
	amount: number;
	drCr: string;
	isDebit: boolean;
	status: string;
	markForDeletion: boolean;
	financialsEntityId: number;
	updatedByUserId: number;
	updatedByUser: User;
	createdAt: string;
	updatedAt: string;
	isOriginalEntry: boolean;
	debitAmount: number;
	creditAmount: number;
	financialsAccountCrossReferenceId: number;
	oldAccountNumber: string;
	accountCrossReference: FinancialsAccountCrossReference;
	financialsProjectTypeId: number;
	projectType: FinancialsProjectType;
	financialsProjectGroupId: number;
	projectGroup: FinancialsProjectGroup;
	financialsAccountGroupId: number;
	financialsAccountSubGroupId: number;

	constructor(object: Object = {}) {
		const properties = [
			'id',
			'journalType',
			'journalDesc',
			'description',
			'financialsAccountObjectId',
			'accountObject',
			'financialsFundId: number',
			'fund',
			'financialsDepartmentSegId',
			'departmentSeg:',
			'financialsDivisionSegId',
			'divisionSeg',
			'financialsProjectId',
			'project',
			'financialsFunctionId',
			'function',
			'financialsAccountId',
			'account',
			'financialsJournalId',
			'journal',
			'financialsInvoiceId',
			'invoice',
			'financialsInvoiceLineItemId',
			'invoiceLineItem',
			'financialsPaymentId',
			'payment',
			'paymentNumber',
			'bankAccountId',
			'bankAccount',
			'fundVal',
			'deptVal',
			'divVal',
			'objVal',
			'projVal',
			'funcVal',
			'accountVal',
			'journalVal',
			'invoiceVal',
			'invoiceLineItemVal',
			'periodVal',
			'financialsPeriodId',
			'period',
			'fyVal',
			'financialsFiscalYearId',
			'fiscalYear',
			'effectiveDate',
			'postedDate',
			'amount',
			'drCr',
			'isDebit',
			'status',
			'markForDeletion',
			'financialsEntityId',
			'updatedByUserId',
			'updatedByUser',
			'createdAt',
			'updatedAt',
			'debitAmount',
			'creditAmount',
			'oldAccountNumber',
			'isOriginalEntry',
			'financialsAccountCrossReferenceId',
			'accountCrossReference',
			'financialsProjectTypeId',
			'projectType',
			'financialsProjectGroupId',
			'projectGroup',
		];
		super(object, properties);
	}
}
