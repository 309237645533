import { DataType } from './dataType';
import { User } from './user';
import { BankAccount } from './bankAccount';
import { FinancialsPeriod } from './financialsPeriod';
import { FinancialsFiscalYear } from './financialsFiscalYear';

export class FinancialsPaymentRun extends DataType {
	id: number;
	bankAccountId: number;
	bankAccount: BankAccount
	paymentDate: string;
	paymentRunNumber: number;
	checkCount: number;
	achCount: number;
	startingCheckNumber: number;
	endingCheckNumber: number;
	startingAchNumber: number;
	endingAchNumber: number;
	effectiveDate: string;
	financialsEntityId: number;
	financialsPeriodId: number;
	period: FinancialsPeriod;
	financialsFiscalYearId: number;
	fiscalYear: FinancialsFiscalYear;
	periodVal: number;
	fyVal: number;
	status: string;
	updatedByUserId: number;
	updatedByUser: User;
	createdAt: string;
	updatedAt: string;
	achNotificationGeneratedAt: string;
	achNotificationGeneratedByUserId: number;
	positivePayGeneratedAt: string;
	positivePayGeneratedByUserId: number;

	constructor(object: Object = {}) {
		const properties = [
			'id',
			'bankAccountId',
			'bankAccount',
			'paymentDate',
			'paymentRunNumber',
			'checkCount',
			'achCount',
			'startingCheckNumber',
			'endingCheckNumber',
			'startingAchNumber',
			'endingAchNumber',
			'effectiveDate',
			'financialsEntityId',
			'financialsPeriodId',
			'period',
			'financialsFiscalYearId',
			'fiscalYear',
			'periodVal',
			'fyVal',
			'status',
			'updatedByUserId',
			'updatedByUser',
			'createdAt',
			'updatedAt',
			'achNotificationGeneratedAt',
			'achNotificationGeneratedByUserId',
			'positivePayGeneratedAt',
			'positivePayGeneratedByUserId'
		];
		super(object, properties);
	}
}
