
import { DataType } from './dataType';
import { MeasurementUnit } from './measurementUnit';
import { FinancialsInvoice} from './financialsInvoice';
import { User } from './user';

export class FinancialsInvoiceLineItem extends DataType {
	id: number;
	financialsInvoiceId: number;
	invoive: FinancialsInvoice;
	lineNumber: number;
	description: string;
	quantity: number;
	unitOfMeasureId: number;
	measurementUnit: MeasurementUnit;
	unitPrice: number;
	extendedAmount: number;
	status: string;
	financialsEntityId: number;
	updatedByUserId: number;
	updatedByUser: User;
	createdAt: string;
	updatedAt: string;


	constructor(object: Object = {}) {
		const properties = [
			'id',
			'financialsInvoiceId',
			'invoice',
			'lineNumber',
			'description',
			'quantity',
			'unitOfMeasureId',
			'measurementUnit',
			'unitPrice',
			'extendedAmount',
			'status',
			'financialsEntityId',
			'updatedByUserId',
			'updatedByUser',
			'createdAt',
			'updatedAt',];
		super(object, properties);
	}
}
