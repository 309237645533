import { DataType } from './dataType';

export class ReceivableStatementSettings extends DataType {
	remittenceInstructions: string;

	constructor(object: Object = {}) {
		const properties = [
						'remittenceInstructions'];
		super(object, properties);

	}
}
