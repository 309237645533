import { DataType } from "./dataType";
import { User } from "./user";

export class ChargeType extends DataType {
  id: number;
  chargeCode: string;
  description: string;
  postingPriority: string;
  cashAccount: string;
  revenueAccount: string;
  receivableAccount: string;
  status: string;
  createdAt: string;
  updatedAt: string;
  updatedByUserId: number;
  updatedByUser: User;
  recurring: boolean;
  billInJanuary: boolean;
  billInFebruary: boolean;
  billInMarch: boolean;
  billInApril: boolean;
  billInMay: boolean;
  billInJune: boolean;
  billInJuly: boolean;
  billInAugust: boolean;
  billInSeptember: boolean;
  billInOctober: boolean;
  billInNovember: boolean;
  billInDecember: boolean;

  constructor(object: Object = {}) {
    var properties = [
      "id",
      "chargeCode",
      "description",
      "postingPriority",
      "cashAccount",
      "revenueAccount",
      "receivableAccount",
      "status",
      "createdAt",
      "updatedAt",
      "updatedByUserId",
      "updatedByUser",
      "recurring",
      "billInJanuary",
      "billInFebruary",
      "billInMarch",
      "billInApril",
      "billInMay",
      "billInJune",
      "billInJuly",
      "billInAugust",
      "billInSeptember",
      "billInOctober",
      "billInNovember",
      "billInDecember",
    ];
    super(object, properties);
  }
}
