
import { DataType } from './dataType';


export class FinancialsPaidInvoiceStatistic extends DataType {
	financialsEntityId: number;
	sevenOrLessCount: number;
	eightToFifteenCount: number;
	sixteenToThirtyCount: number;
	thirtyoneToSixtyCount: number;
	sixtyoneOrMoreCount: number;
	paidAfterDueDateCount: number;
	sevenOrLessTotal: number;
	eightToFifteenTotal: number;
	sixteenToThirtyTotal: number;
	thirtyoneToSixtyTotal: number;
	sixtyoneOrMoreTotal: number;
	paidAfterDueDateTotal: number;
	oneTimeVendorCount: number;
	standardVendorCount: number;
	oneTimeVendorTotal: number;
	standardVendorTotal: number;
	paidCount: number;
	paidTotal: number;


	constructor(object: Object = {}) {
		const properties = [
			'financialsEntityId',
			'sevenOrLessCount',
			'eightToFifteenCount',
			'sixteenToThirtyCount',
			'thirtyoneToSixtyCount',
			'sixtyoneOrMoreCount',
			'paidAfterDueDateCount',
			'sevenOrLessTotal',
			'eightToFifteenTotal',
			'sixteenToThirtyTotal',
			'thirtyoneToSixtyTotal',
			'sixtyoneOrMoreTotal',
			'paidAfterDueDateTotal',
			'oneTimeVendorCount',
			'standardVendorCount',
			'oneTimeVendorTotal',
			'standardVendorTotal',
			'paidCount',
			'paidTotal',];
		super(object, properties);
	}
}
