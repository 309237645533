import { DataType } from './dataType';
import { User } from './user';
import { FinancialsFund } from './financialsFund';
import { FinancialsAccountObject } from './financialsAccountObject';
import { BankAccount } from './bankAccount';


export class FinancialsPooledCashFundEquity extends DataType {
	id: number;
	bankAccountId: number;
	bankAccount: BankAccount;
	financialsEntityId: number;
	financialsFundId: number;
	fund: FinancialsFund;
	equityAccountObjectId: number;
	equityAccountObject: FinancialsAccountObject;
	updatedByUserId: number;
	updatedByUser: User;
	createdAt: string;
	updatedAt: string;

	constructor(object: Object = {}) {
		const properties = [
			'id',
			'bankAccountId',
			'bankAccount',
			'financialsEntityId',
			'financialsFundId',
			'fund',
			'equityAccountObjectId',
			'equityAccountObject',
			'updatedByUserId',
			'updatedByUser',
			'createdAt',
			'updatedAt',
		];
		super(object, properties);
	}
}
