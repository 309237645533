import { DataType } from "./dataType";
import { User } from "./user";
import { ChargeType } from "./chargeType";

export class ChargeTypeAmount extends DataType {
  id: number;
  chargeTypeId: number;
  chargeType: ChargeType;
  effectiveDate: string;
  amount: number;
  createdAt: string;
  updatedAt: string;
  updatedByUserId: number;
  updatedByUser: User;

  constructor(object: Object = {}) {
    var properties = [
      "id",
      "chargeTypeId",
      "chargeType",
      "effectiveDate",
      "amount",
      "createdAt",
      "updatedAt",
      "updatedByUserId",
      "updatedByUser",
    ];
    super(object, properties);
  }
}
