import { DataType } from './dataType';
import { User } from './user';
import { ReceivableAccount } from './receivableAccount';
import { ChargeType } from './chargeType';
import { AgingStep } from './agingStep';

export class ReceivableCharge extends DataType {
  id: number;
  accountId: number;
  account: ReceivableAccount;
  chargeTypeId: number;
  chargeType: ChargeType;
  recurring: boolean;
  recurringChargeId: number;
  recurringCharge: ReceivableCharge;
  additionalInformation: string;
  quantity: number;
  amount: number;
  unpaidAmount: number;
  billedAt: string;
  billedByUserId: number;
  billedByUser: User;
  agingStepId: number;
  agingStep: AgingStep;
  status: string;
  updatedByUserId: number;
  udpatedByUser: User;
  createdAt: string;
  updatedAt: string;
  parentReceivableChargeId: number;
  parentReceivableCharge: ReceivableCharge;
  jobId: number;
  manualStopReason: string;

  constructor(object: Object = {}) {
    let properties = [
      'id',
      'accountId',
      'account',
      'chargeTypeId',
      'chargeType',
      'recurring',
      'recurringChargeId',
      'recurringCharge',
      'additionalInformation',
      'quantity',
      'amount',
      'unpaidAmount',
      'billedAt',
      'billedByUserId',
      'billedByUser',
      'agingStepId',
      'agingStep',
      'status',
      'updatedByUserId',
      'udpatedByUser',
      'createdAt',
      'updatedAt',
      'parentReceivableChargeId',
      'parentReceivableCharge',
      'jobId',
      'manualStopReason'
    ];
    super(object, properties);
  }
}
