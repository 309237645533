import { DataType } from './dataType';
import { User } from './user';

export class RolePermissionGroup extends DataType {
	id: number;
	roleId: number;
	permissionGroupId: number;
	updatedByUserId: number;
	updatedByUser: User;
  createdAt: string;
  updatedAt: string;


	constructor(object: Object = {}){ 
		var properties = [
			'id',
			'roleId',
			'permissionGroupId',
			'updatedByUserId',
			'updatedByUser',
			'createdAt',
			'updatedAt'	
		];
		super(object, properties);
	}
}