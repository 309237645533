
import { DataType } from './dataType';
import { FinancialsPeriod } from './financialsPeriod';
import { FinancialsFiscalYear } from './financialsFiscalYear';
import { FinancialsVendor } from './financialsVendor';
import { FinancialsOneTimeVendor } from './financialsOneTimeVendor';
import { FinancialsPayment } from './financialsPayment';	
import { User } from './user';
import { FinancialsWarrant } from './financialsWarrant';	
export class FinancialsInvoice extends DataType {
	id: number;
	invoiceNumber: number;
	financialsVendorId: number;
	vendor: FinancialsVendor;
	invoiceDate: string;
	dueDate: string;
	effectiveDate: string;
	periodVal: number;
	fyVal: number;
	datePaid: string;
	vendorInvoiceNumber: string;
	reference: string;
	totalAmount: number;
	status: string;
	financialsPeriodId: number;
	period: FinancialsPeriod;
	financialsFiscalYearId: number;
	fiscalYear: FinancialsFiscalYear;
	financialsEntityId: number;
	updatedByUserId: number;
	updatedByUser: User;
	createdAt: string;
	updatedAt: string;
	postedDate: string;
	postedByUserId: number;
	postedByUser: User;
	scheduledDate: string;
	scheduledByUserId: number;
	scheduledByUser: User;
	canceledDate: string;
	canceledByUserId: number;
	canceledByUser: User;
	canceledReason: string;
	paidByUserId: number;
	paidByUser: User;
	financialsOneTimeVendorId: number;
	oneTimeVendor: FinancialsOneTimeVendor;
	financialsPaymentId: number;
	payment: FinancialsPayment;
	financialsWarrantId: number;
	warrant: FinancialsWarrant;

	constructor(object: Object = {}) {
		const properties = [
			'id',
			'invoiceNumber',
			'financialsVendorId',
			'vendor',
			'invoiceDate',
			'dueDate',
			'effectiveDate',
			'periodVal',
			'fyVal',
			'datePaid',
			'vendorInvoiceNumber',
			'reference',
			'totalAmount',
			'status',
			'financialsPeriodId',
			'period',
			'financialsFiscalYearId',
			'fiscalYear',
			'financialsEntityId',
			'updatedByUserId',
			'updatedByUser',
			'createdAt',
			'updatedAt',
			'postedDate',
			'postedByUserId',
			'postedByUser',
			'scheduledDate',
			'scheduledByUserId',
			'scheduledByUser',
			'canceledDate',
			'canceledByUserId',
			'canceledByUser',
			'canceledReason',
			'paidByUserId',
			'paidByUser',
			'financialsOneTimeVendorId',
			'oneTimeVendor',
			'financialsPaymentId',
			'payment',
			'financialsWarrantId',
			'warrant'
		];
		super(object, properties);
	}
}
