import { DataType } from './dataType';
import { Contact } from './contact';
import { Business } from './business';
import { State } from './state';
import { Country } from './country';
import { User } from './user';
import { Bank } from './bank';

export class FinancialsVendor extends DataType {
	id: number;
	status: string;
	vendorNumber: number;
	remitPhone: number;
	updatedByUserId: number;
	updatedByUser: User;
	remitAddress1: string;
	remitAddress2: string;
	remitCity: string;
	remitStateId: number;
    remitState: State;
	remitZipCode: string;
	createdAt: string;
    updatedAt: string;
	contactId: number;
    contact: Contact;
    businessId: number;
    business: Business;
    remitCountryId: number;
    remitCountry: Country;
	remitStateName: string;
	remitPhoneExtension: number;
	remitAddress3: string;
	website: string;
	remitEmail: string;
	nameOnCheck: string;
	remitContactName: string;
	taxId: string;
	taxIdType: string;
	miscVendor: boolean;
	notes: string;
	howPaid: string;
	bankId: number;
	bank: Bank;
	eftBankAccountNumber: string;
	eftPrenoteComplete: boolean;
	oneInvoicePerCheck: boolean;
	eligibleFor1099: boolean;
	w9OnFile: boolean;
	prenoteRequired: boolean;


	constructor(object: Object = {}) {
		const properties = [
			'id',
			'status',
			'vendorNumber',
			'remitPhone',
			'updatedByUserId',
			'updatedByUser',
			'remitAddress1',
			'remitAddress2',
			'remitCity',
			'remitStateId',
			'remitState',
			'remitZipCode',
			'createdAt',
			'updatedAt',
			'contactId',
			'contact',
			'businessId',
			'business',
			'remitCountryId',
			'remitCountry',
			'remitStateName',
			'remitPhoneExtension',
			'remitAddress3',
			'website',
			'remitEmail',
			'nameOnCheck',
			'remitContactName',
			'taxId',
			'taxIdType',
			'miscVendor',
			'notes',
			'howPaid',
			'bankId',
			'bank',
			'eftBankAccountNumber',
			'eftPrenoteComplete',
			'oneInvoicePerCheck',
			'eligibleFor1099',
			'w9OnFile',
			'prenoteRequired'];
		super(object, properties);
	}
}
