import { DataType } from './dataType';
import { Application } from './application';

export class Role extends DataType {
	id: number;
	name: string;
	permissionsList: Array<any>;
	description: string;
	applicationId: number;
	application: Application;
	restricted: boolean;
	createdAt: string;
  updatedAt: string;

	constructor(object: Object = {}){ 
		var properties = [
			'id',
			'name',
			'permissionsList',
			'description',
			'applicationId',
			'application',
			'restricted',
			'createdAt',
			'updatedAt'
		 ];
		super(object, properties);
	}
}