import { Injectable } from '@angular/core';

import {
	ResourceService,
	DataService
} from '../services/services';

@Injectable()
export class ReceivableOverpaymentSettingsResource extends ResourceService {

	constructor(public dataService: DataService) {
		super(dataService, 'settings/receivables/overpayments');
	}
}
