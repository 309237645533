import { DataType } from './dataType';

export class FinancialsReservedAccountsSettings extends DataType {
	cash: number;
	revenue: number;
	expenditures: number;
	fundBalance: number;
	retainedEquity: number;
	payables: number;

	constructor(object: Object = {}){ 
		var properties = [
							'cash',
							'revenue',
							'expenditures',
							'fundBalance',
							'retainedEquity',
							'payables',
						];
		super(object, properties);

	}
}