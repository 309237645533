import { DataType } from './dataType';
import { FinancialsAccount } from './financialsAccount';
import { User } from './user';

export class FinancialsAccountCrossReference extends DataType {
	id: number;
	oldAccountNumber: string;
	financialsAccountId: number;
	account: FinancialsAccount;
	markForDeletion: string;
	financialsEntityId: number;
	updatedByUserId: number;
	updatedByUser: User;
	createdAt: string;
	updatedAt: string;

	constructor(object: Object = {}) {
		const properties = [
			'id',
			'oldAccountNumber',
			'financialsAccountId',
			'account',
			'markForDeletion',
			'financialsEntityId',
			'updatedByUserId',
			'updatedByUser',
			'createdAt',
			'updatedAt',
		];
		super(object, properties);
	}
}
