import { DataType } from './dataType';
import { User } from './user';
import { FinancialsAccountRule } from './financialsAccountRule';
import { FinancialsAccountSubGroup } from './financialsAccountSubGroup';
import { FinancialsAccountGroup } from './financialsAccountGroup';


export class FinancialsAccountObject extends DataType {
	id: number;
	objectNumber: string;
	description: string;
	accountType: string;
	financialsAccountRuleId: number;
	accountRule: FinancialsAccountRule;
	financialsEntityId: number;
	financialsAccountGroupId: number;
	accountGroup: FinancialsAccountGroup;
	financialsAccountSubGroupId: number;
	accountSubGroup: FinancialsAccountSubGroup;
	status: string;
	updatedByUserId: number;
	updatedByUser: User;
	createdAt: string;
	updatedAt: string;

	constructor(object: Object = {}) {
		const properties = [
			'id',
			'objectNumber',
			'description',
			'accountType',
			'financialsAccountRuleId',
			'accountRule',
			'financialsEntityId',
			'financialsAccountGroupId',
			'accountGroup',
			'financialsAccountSubGroupId',
			'accountSubGroup',
			'status',
			'updatedByUserId',
			'updatedByUser',
			'createdAt',
			'updatedAt',
		];
		super(object, properties);
	}
}
