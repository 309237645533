import { Injectable } from "@angular/core";

import { ResourceService, DataService } from "../services/services";

@Injectable()
export class FinancialsProjectAttachmentsResource extends ResourceService {
  constructor(public dataService: DataService) {
    super(dataService, "financialsprojectattachments");
  }

  init(params) {
    this.url = "financialsprojectattachments";

    if (params.urlModifiers) {
      let modifiers = params.urlModifiers;

      if (modifiers.financialsProjectId) {
        this.url = "financialsprojects/:financialsProjectId/attachments";
      }

      if (modifiers.file) {
        this.url += "/" + modifiers.attachmentId + "/file";
      }
    }
  }
}
