import { DataType } from './dataType';

export class FinancialsAchNotificationSettings extends DataType {
	senderName: string;
	contactPhone: string;
	contactEmail: string;

	constructor(object: Object = {}){ 
		var properties = [
							'senderName',
							'contactPhone',
							'contactEmail'
						];
		super(object, properties);

	}
}