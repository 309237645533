import { DataType } from './dataType';
import { ReceivableAccount } from './receivableAccount';

export class ReceivableTransaction extends DataType {
  accountId: number;
  account: ReceivableAccount;
  transactionType: string;
  transactionDate: string;
  sourceId: number;
  description: string;
  additionalInformation: string;
  amount: number;
  amountRemaining: number;
  status: string;

  constructor(object: Object = {}) {
    const properties = [
      'accountId',
      'account',
      'transactionType',
      'transactionDate',
      'sourceId',
      'description',
      'additionlInformation',
      'amount',
      'amountRemaining',
      'status'
    ];
    super(object, properties);
  }
}
