import { DataType } from './dataType';
import { Application } from './application';
import { User } from './user';

export class PermissionGroup extends DataType {
	id: number;
	name: string;
	applicationId: number;
	application: Application;
	updatedByUserId: number;
	updatedByUser: User;
  createdAt: string;
  updatedAt: string;

	constructor(object: Object = {}){ 
		var properties = [
			'id',
			'name',
			'applicationId',
			'application',
			'updatedByUserId',
			'updatedByUser',
			'createdAt',
			'updatedAt'
		];
		super(object, properties);
	}
}