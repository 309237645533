import { DataType } from './dataType';
import { FinancialsAccountGroup } from './financialsAccountGroup';
import { User } from './user';

export class FinancialsAccountSubGroup extends DataType {
	id: number;
	subGroup: string;
	description: string;
	financialsAccountGroupId: number;
	accountGroup: FinancialsAccountGroup;
	financialsEntityId: number;
	status: string;
	subGroupSequence: number;
	updatedByUserId: number;
	udpatedByUser: User;
	createdAt: string;
	updatedAt: string;

	constructor(object: Object = {}) {
		const properties = [
							'id',
							'subGroup',
							'description',
							'financialsAccountGroupId',
							'accountGroup',
							'financialsEntityId',
							'status',
							'subGroupSequence',
							'updatedByUserId',
							'updatedByUser',
							'createdAt',
							'updatedAt'];
		super(object, properties);
	}
}
