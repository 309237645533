import { DataType } from './dataType';
import { User } from './user';
import { ReceivableAccount } from './receivableAccount';

export class ReceivablePayment extends DataType {
  id: number;
  receivableAccountId: number;
  account: ReceivableAccount;
  receiptId: number;
  receiptLineId: number;
  paymentDate: string;
  amount: number;
  unappliedAmount: number;
  updatedByUserId: number;
  updatedByUser: User;
  createdAt: string;
  updatedAt: string;
  reversedAt: string;
  status: string;

  constructor(object: Object = {}) {
    const properties = [
      'id',
      'receivableAccountId',
      'account',
      'receiptId',
      'receiptLineId',
      'paymentDate',
      'amount',
      'unappliedAmount',
      'updatedByUserId',
      'updatedByUser',
      'createdAt',
      'updatedAt',
      'reversedAt',
      'status'
    ];
    super(object, properties);
  }
}
