import { DataType } from './dataType';
import { FinancialsProjectType } from './financialsProjectType';
import { FinancialsProjectGroup } from './financialsProjectGroup';
import { User } from './user';

export class FinancialsProject extends DataType {
	id: number;
	name: string;
	description: string;
	fromDate: string;
	toDate: string;
	financialsProjectTypeId: number;
	projectType: FinancialsProjectType;
	financialsProjectGroupId: number;
	projectGroup: FinancialsProjectGroup;
	fundingType: string;
	fundingSource: string;
	federalGrantId: string;
	stateGrantId: string;
	firstMonthGrantFy: number;
	expirationDate: string;
	status: string;
	financialsEntityId: number;
	updatedByUserId: number;
	updatedByUser: User;
	createdAt: string;
	updatedAt: string;

	constructor(object: Object = {}) {
		const properties = [
							'id',
							'name',
							'description',
							'fromDate',
							'toDate',
							'financialsProjectTypeId',
							'projectType',
							'financialsProjectGroupId',
							'projectGroup',
							'fundingType',
							'fundingSource',
							'federalGrantId',
							'stateGrantId',
							'firstMonthGrantFy',
							'expirationDate',
							'status',
							'financialsEntityId',
							'updatedByUserId',
							'udpatedByUser',
							'createdAt',
							'updatedAt'];
		super(object, properties);
	}
}
