import { DataType } from './dataType';
import { User } from './user';
import { FinancialsFund } from './financialsFund';
import { FinancialsDepartmentSeg } from './financialsDepartmentSeg';
import { FinancialsDivisionSeg } from './financialsDivisionSeg';
import { FinancialsProject } from './financialsProject';
import { FinancialsFunction } from './financialsFunction';

export class FinancialsAccountRule extends DataType {
	id: number;
	rule: string;
	description: string;
	accountType: string;
	normalState: string;
	fundRequired: string;
	deptRequired: string;
	divisionRequired: string;
	functionRequired: string;
	projectRequired: string;
	fixed: boolean;
	financialsFundId: number;
	fund: FinancialsFund;
	financialsDepartmentSegId: number;
	departmentSeg: FinancialsDepartmentSeg;
	financialsDivisionSegId: number;
	divisionSeg: FinancialsDivisionSeg;
	financialsFunctionId: number;
	function: FinancialsFunction;
	financialsProjectId: number;
	project: FinancialsProject;
	financialsEntityId: number;
	status: string;
	updatedByUserId: number;
	updatedByUser: User;
	createdAt: string;
	updatedAt: string;

	constructor(object: Object = {}) {
		const properties = [
			'id',
			'rule',
			'description',
			'accountType',
			'normalState',
			'fundRequired',
			'deptRequired',
			'divisionRequired',
			'functionRequired',
			'projectRequired',
			'fixed',
			'financialsFundId',
			'fund',
			'financialsDepartmentSegId',
			'departmentSeg',
			'financialsDivisionSegId',
			'divisionSeg',
			'financialsFunctionId',
			'function',
			'financialsProjectId',
			'project',
			'financialsEntityId',
			'status',
			'updatedByUserId',
			'updatedByUser',
			'createdAt',
			'updatedAt',
		];
		super(object, properties);
	}
}
