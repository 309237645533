import { DataType } from './dataType';
import { User } from './user';
import { FinancialsPayment } from './financialsPayment';
import { FinancialsPaymentRun } from './financialsPaymentRun';

export class EmailDeliveryLog extends DataType {
	id: number;
	financialsPaymentRunId: number;
	paymentRun: FinancialsPaymentRun;
	financialsPaymentId: number;
	payment: FinancialsPayment;
	entityId: number;
	emailAddress: string;
	emailTemplate: string;
	uniqueEmailId: string;
	successfullyDelivered: boolean;
	deliveryError: string;
	deliveryAt: string;
	updatedByUserId: number;
	updatedByUser: User;
	createdAt: string;
	updatedAt: string;

	constructor(object: Object = {}) {
		const properties = [
			'id',
			'financialsPaymentRunId',
			'paymentRun',
			'financialPaymentId',
			'payment',
			'entityId',
			'emailAddress',
			'emailTemplate',
			'uniqueEmailId',
			'successfullyDelivered',
			'deliveryError',
			'deliveryAt',
			'updatedByUserId',
			'updatedByUser',
			'createdAt',
			'updatedAt',
		];
		super(object, properties);
	}
}
