import { DataType } from './dataType';
import { User } from './user';
import { FinancialsFundType } from './financialsFundType';

export class FinancialsFund extends DataType {
	id: number;
	fundNumber: string;
	description: string;
	fundCategory: string;
	isMajorFund: boolean;
	financialsEntityId: number;
	financialsFundTypeId: number;
	fundType: FinancialsFundType;
	status: string;
	updatedByUserId: number;
	updatedByUser: User;
	createdAt: string;
	updatedAt: string;

	constructor(object: Object = {}) {
		const properties = [
			'id',
			'fundNumber',
			'description',
			'fundCategory',
			'isMajorFund',
			'financialsEntityId',
			'financialsFundTypeId',
			'fundType',
			'status',
			'updatedByUserId',
			'updatedByUser',
			'createdAt',
			'updatedAt',
		];
		super(object, properties);
	}
}
