import { DataType } from './dataType';

export class FinancialsWarrantProcessing extends DataType {
	useWarrantProcessing: boolean;
	lastWarrantNumber: number;
	warrantReportSummaryNarrative: string;

	constructor(object: Object = {}){ 
		var properties = [
							'useWarrantProcessing',
							'lastWarrantNumber',
							'warrantReportSummaryNarrative'
						];
		super(object, properties);

	}
}