import { ReceivableCharge } from './receivableCharge';
import { ReceivablePayment } from './receivablePayment';
import { DataType } from './dataType';
import { User } from './user';

export class ReceivablePaymentDetail extends DataType {
  id: number;
  receivablePaymentId: number;
  receivablePayment: ReceivablePayment;
  receivableChargeId: number;
  receivableCharge: ReceivableCharge;
  dateApplied: string;
  amountApplied: number;
  updatedByUserId: number;
  updatedByUser: User;
  createdAt: string;
  updatedAt: string;

  constructor(object: Object = {}) {
    const properties = [
      'id',
      'receivablePaymentId',
      'receivablePayment',
      'receivableChargeId',
      'receivableCharge',
      'dateApplied',
      'amountApplied',
      'updatedByUserId',
      'updatedByUser',
      'createdAt',
      'updatedAt',
    ];
    super(object, properties);
  }
}
