import { Injectable } from "@angular/core";

import { ResourceService, DataService } from "../services/services";

@Injectable()
export class FinancialsVendorAttachmentsResource extends ResourceService {
  constructor(public dataService: DataService) {
    super(dataService, "financialsvendorattachments");
  }

  init(params) {
    this.url = "financialsvendorattachments";

    if (params.urlModifiers) {
      let modifiers = params.urlModifiers;

      if (modifiers.financialsVendorId) {
        this.url = "financialsvendors/:financialsVendorId/attachments";
      }

      if (modifiers.file) {
        this.url += "/" + modifiers.attachmentId + "/file";
      }
    }
  }
}
