import { DataType } from './dataType';

export class FinancialsAccountConfig extends DataType {
	id: number;
	fundUseSegment: boolean;
	fundMaxLength: number;
	fundLength: number;
	fundAllowCharacters: boolean;
	fundSeparatorCharacter: string;
	fundShortName: string;
	fundAltLabel: string;
	deptUseSegment: boolean;
	deptMaxLength: number;
	deptLength: number;
	deptAllowCharacters: boolean;
	deptSeparatorCharacter: string;
	deptShortName: string;
	deptAltLabel: string;
	divUseSegment: boolean;
	divMaxLength: number;
	divLength: number;
	divAllowCharacters: boolean;
	divSeparatorCharacter: string;
	divShortName: string;
	divAltLabel: string;
	objUseSegment: boolean;
	objMaxLength: number;
	objLength: number;
	objAllowCharacters: boolean;
	objShortName: string;
	objAltLabel: string;
	funcUseSegment: boolean;
	funcMaxLength: number;
	funcLength: number;
	funcAllowCharacters: boolean;
	funcShortName: string;
	funcAltLabel: string;
	projUseSegment: boolean;
	projMaxLength: number;
	projLength: number;
	projAllowCharacters: boolean;
	projShortName: string;
	projAltLabel: string;
	owningEntityId: number;
	createdAt: string;
	updatedAt: string;

	constructor(object: Object = {}) {
		const properties = [
			'id',
			'fundUseSegment',
			'fundMaxLength',
			'fundLength',
			'fundAllowCharacters',
			'fundSeparatorCharacter',
			'fundShortName',
			'fundAltLabel',
			'deptUseSegment',
			'deptMaxLength',
			'deptLength',
			'deptAllowCharacters',
			'deptSeparatorCharacter',
			'deptShortName',
			'deptAltLabel',
			'divUseSegment',
			'divMaxLength',
			'divLength',
			'divAllowCharacters',
			'divSeparatorCharacter',
			'divShortName',
			'divAltLabel',
			'objUseSegment',
			'objMaxLength',
			'objLength',
			'objAllowCharacters',
			'objShortName',
			'objAltLabel',
			'funcUseSegment',
			'funcMaxLength',
			'funcLength',
			'funcAllowCharacters',
			'funcShortName',
			'funcAltLabel',
			'projUseSegment',
			'projMaxLength',
			'projLength',
			'projAllowCharacters',
			'projShortName',
			'projAltLabel',
			'owningEntityId',
			'createdAt',
			'updatedAt',
		];
		super(object, properties);
	}
}
