import { Injectable } from "@angular/core";

import { ResourceService, DataService } from "../services/services";

@Injectable()
export class FinancialsJournalAttachmentsResource extends ResourceService {
  constructor(public dataService: DataService) {
    super(dataService, "financialsjournalattachments");
  }

  init(params) {
    this.url = "financialsjournalattachments";

    if (params.urlModifiers) {
      let modifiers = params.urlModifiers;

      if (modifiers.financialsJournalId) {
        this.url = "financialsjournals/:financialsJournalId/attachments";
      }

      if (modifiers.file) {
        this.url += "/" + modifiers.attachmentId + "/file";
      }
    }
  }
}
