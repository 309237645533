import { DataType } from './dataType';
import { User } from './user';

export class FinancialsWarrant extends DataType {
	id: number;
	warrantNumber: number;
	status: string;
	financialsEntityId: number;
	updatedByUserId: number;
	updatedByUser: User;
	createdAt: string;
	updatedAt: string;

	constructor(object: Object = {}) {
		const properties = [
			'id',
			'warrantNumber',
			'status',
			'financialsEntityId',
			'updatedByUserId',
			'updatedByUser',
			'createdAt',
			'updatedAt',
		];
		super(object, properties);
	}
}
