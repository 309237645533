import { DataType } from './dataType';

export class PaymentEntrySettings extends DataType {
	showCommentsDefault: boolean;

	constructor(object: Object = {}) {
		const properties = [
						'showCommentsDefault'];
		super(object, properties);

	}
}
