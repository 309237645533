import { ReceivableCharge } from './receivableCharge';
import { DataType } from './dataType';
import { User } from './user';
import { ReceivableAccount } from './receivableAccount';
import { ChargeType } from './chargeType';

export class ReceivableAdjustment extends DataType {
  id: number;
  receivableAccountId: number;
  account: ReceivableAccount;
  chargeTypeId: number;
  chargeType: ChargeType;
  adjustmentDate: string;
  description: string;
  amount: number;
  receivableChargeId: number;
  receivableCharge: ReceivableCharge;
  status: string;
  updatedByUserId: number;
  udpatedByUser: User;
  createdAt: string;
  updatedAt: string;

  constructor(object: Object = {}) {
    const properties = [
      'id',
      'receivableAccountId',
      'account',
      'chargeTypeId',
      'chargeType',
      'adjustmentDate',
      'description',
      'amount',
      'receivableChargeId',
      'receivableCharge',
      'status',
      'updatedByUserId',
      'udpatedByUser',
      'createdAt',
      'updatedAt'
    ];
    super(object, properties);
  }
}
