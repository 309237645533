import { DataType } from './dataType';

export class ReceivableOverpaymentSettings extends DataType {
	overpaymentsCash: string;
	overpaymentsPayable: string;

	constructor(object: Object = {}) {
		const properties = [
						'overpaymentsCash',
						'overpaymentsPayable'];
		super(object, properties);

	}
}
