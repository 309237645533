import { DataType } from "./dataType";
import { User } from "./user";
import { DocumentType } from "./documentType";
import { BusinessAttachment } from "./businessAttachment";
import { ReceivableAccountAttachment } from "./receivableAccountAttachment";
import { FinancialsJournalAttachment } from "./financialsJournalAttachment";
import { FinancialsProjectAttachment } from "./financialsProjectAttachment";
import { CustomerAttachment } from "./customerAttachment";

export class Attachment extends DataType {
  id: number;
  name: string;
  location: string;
  description: string;
  updatedByUserId: number;
  updatedByUser: User;
  documentTypeId: number;
  documentType: DocumentType;
  opportunityAttachmentDetail: any;
  businessAttachmentDetail: BusinessAttachment;
  receivableAccountAttachmentDetail: ReceivableAccountAttachment;
  customerAttachmentDetail: CustomerAttachment;
  financialsJournalAttachmentDetail: FinancialsJournalAttachment;
  financialsProjectAttachmentDetail: FinancialsProjectAttachment;
  createdAt: string;
  updatedAt: string;

  constructor(object: Object = {}) {
    var properties = [
      "id",
      "name",
      "location",
      "description",
      "updatedByUserId",
      "updatedByUser",
      "documentTypeId",
      "documentType",
      "opportunityAttachmentDetail",
      "businessAttachmentDetail",
      "receivableAccountAttachmentDetail",
      "customerAttachmentDetail",
      "financialsJournalAttachmentDetail",
      "financialsProjectAttachmentDetail",
      "createdAt",
      "updatedAt",
    ];
    super(object, properties);
  }
}
