import { DataType } from './dataType';
import { FinancialsFund } from './financialsFund';
import { FinancialsDepartmentSeg } from './financialsDepartmentSeg';
import { FinancialsDivisionSeg } from './financialsDivisionSeg';
import { FinancialsAccountObject } from './financialsAccountObject';
import { User } from './user';

export class FinancialsAccountByObject extends DataType {
	objectNumber: string;
	objectDescription: string;
	id: number;
	accountNumber: string;
	financialsAccountObjectId: number;
	accountObject: FinancialsAccountObject;
	financialsFundId: number;
	fund: FinancialsFund;
	financialsDepartmentSegId: number;
	departmentSeg: FinancialsDepartmentSeg;
	financialsDivisionSegId: number;
	divisionSeg: FinancialsDivisionSeg;
	fundVal: string;
	deptVal: string;
	divVal: string;
	objVal: string;
	status: string;
	fromDate: string;
	toDate: string;
	financialsEntityId: number;
	updatedByUserId: number;
	updatedByUser: User;
	oldAccountNumber: string;
	createdAt: string;
	updatedAt: string;

	constructor(object: Object = {}) {
		const properties = [
			'objectNumber',
			'objectDescription',
			'id',
			'accountNumber',
			'financialsAccountObjectId',
			'accountObject',
			'financialsFundId',
			'fund',
			'financialsDepartmentSegId',
			'departmentSeg',
			'financialsDivisionSegId',
			'divisionSeg',
			'fundVal',
			'deptVal',
			'divVal',
			'objVal',
			'status',
			'fromDate',
			'toDate',
			'financialsEntityId',
			'updatedByUserId',
			'updatedByUser',
			'oldAccountNumber',
			'createdAt',
			'updatedAt',
		];
		super(object, properties);
	}
}
