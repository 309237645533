import { DataType } from './dataType';
import { User } from './user';
import { Bank } from './bank';
import { FinancialsVendor } from './financialsVendor';
import { FinancialsOneTimeVendor } from './financialsOneTimeVendor';
import { FinancialsPeriod } from './financialsPeriod';
import { FinancialsFiscalYear } from './financialsFiscalYear';
import { FinancialsPaymentRun } from './financialsPaymentRun';

export class FinancialsPayment extends DataType {
	id: number;
	financialsPaymentRunId: number;
	paymentRun: FinancialsPaymentRun;
	financialsVendorId: number;
	vendor:	FinancialsVendor;
	financialsOneTimeVendorId: number;
	oneTimeVendor: FinancialsOneTimeVendor;
	paymentDate: string;
	paymentMethod: string;
	paymentNumber: number;
	paymentAmount: number;
	eftBankAccountNumber: number;
	bankId: number;
	bank: Bank;
	effectiveDate: string;
	financialsEntityId: number;
	financialsPeriodId: number;
	period: FinancialsPeriod;
	financialsFiscalYearId: number;
	fiscalYear: FinancialsFiscalYear;
	periodVal: number;
	fyVal: number;
	status: string;
	dateVoided: string;
	voidedByUserId: number;
	voidReason: string;
	voidedByUser: User;
	dateReconciled: string;
	reconciledByUserId: number;
	reconciledByUser: User;
	updatedByUserId: number;
	updatedByUser: User;
	createdAt: string;
	updatedAt: string;

	constructor(object: Object = {}) {
		const properties = [
			'id',
			'financialsPaymentRunId',
			'paymentRun',
			'financialsVendorId',
			'vendor',
			'financialsOneTimeVendorId',
			'onetimeVendor',
			'paymentDate',
			'paymentMethod',
			'paymentNumber',
			'paymentAmount',
			'eftBankAccountNumber',
			'bankId',
			'bank',
			'effectiveDate',
			'financialsEntityId',
			'financialsPeriodId',
			'period',
			'financialsFiscalYearId',
			'fiscalYear',
			'periodVal',
			'fyVal',
			'status',
			'dateVoided',
			'voidedByUserId',
			'voidedByUser',
			'voidReason',
			'dateReconciled',
			'reconciledByUserId',
			'reconciledByUser',
			'updatedByUserId',
			'updatedByUser',
			'createdAt',
			'updatedAt',
		];
		super(object, properties);
	}
}
