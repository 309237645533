import { DataType } from "./dataType";
import { User } from "./user";
import { AgingGroup } from "./agingGroup";

export class AgingStep extends DataType {
  id: number;
  groupId: number;
  agingGroup: AgingGroup;
  name: string;
  numberOfDays: number;
  createdAt: string;
  updatedAt: string;
  updatedByUserId: number;
  updatedByUser: User;

  constructor(object: Object = {}) {
    var properties = [
      "id",
      "groupId",
      "agingGroup",
      "name",
      "numberOfDays",
      "createdAt",
      "updatedAt",
      "updatedByUserId",
      "updatedByUser",
    ];
    super(object, properties);
  }
}
