import { DataType } from "./dataType";
import { FinancialsInvoice } from './financialsInvoice';
import { Attachment } from "./attachment";
import { User } from "./user";

export class FinancialsInvoiceAttachment extends DataType {
  id: number;
  financialsInvoiceId: number;
  invoice: FinancialsInvoice;
  financialsEntityId: number
  attachmentId: number;
  attachment: Attachment;
  expirationDate: string;
  createdByUserId: number;
  createByUser: User;
  createdAt: string;
  updatedAt: string;

  constructor(object: Object = {}) {
    var properties = [
      "id",
      "financialsInvoiceId",
      "invoice",
      "financialsEntityId",
      "attachmentId",
      "attachment",
      "expirationDate",
      "createdByUserId",
      "createByUser",
      "createdAt",
      "updatedAt",
    ];
    super(object, properties);
  }
}
