import { DataType } from './dataType';

export class FinancialsCashManagementOptions extends DataType {
	useDueToDueFrom: boolean;
	usePooledCash: boolean;
	pooledCashFund: number;

	constructor(object: Object = {}){ 
		var properties = [
							'useDueToDueFrom',
							'usePooledCash',
							'pooledCashFund',
						];
		super(object, properties);

	}
}