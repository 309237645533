import { DataType } from './dataType';
import { Bank } from './bank';
import { Entity } from './entity';
import { FinancialsAccount } from './financialsAccount';

export class BankAccount extends DataType {
	id: number;
	bankId: number;
	bank: Bank;
	name: string;
	description: string;
	type: string;
	number: string;
	status: string;
	entityId: number;
	entity: Entity;
	lastCheckNumber: number;
	lastEftNumber: number;
	financialsAccountId: number;
	account: FinancialsAccount;
	constructor(object: Object = {}){ 
		var properties = [
							"id",
							'bankId',
							'bank',
							'name',
							'description',
							'type',
							'number',
							'status',
							'entityId',
							'entity',
							'lastCheckNumber',
							'lastEftNumber',
							'financialsAccountId',
							'financialsAccount'];
		super(object, properties);

		if(!object['bank']){
			this.bank = new Bank();
		}
	}
}