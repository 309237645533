import { DataType } from './dataType';

export class ReceivablePendingCharge extends DataType {
  accountNumber: number;
  accountTypeId: number;
  accountType: string;
  accountTypeDescription: string;
  accountStatus: string;
  customerNumber: number;
  customerName: string;
  customerStatus: string;
  chargeTypeId: number;
  chargeCode: string;
  chargeDescription: string;
  rcId: number;
  rcAccountId: number;
  additionalInformation: string;
  recurring: boolean;
  parentReceivableChargeId: number;
  chargeStatus: string;
  quantity: number;
  amount: number;
  createdAt: string;
  rccId: number;
  rccAccountId: number;
  recurringCriteriaStatus: string;
  startDate: string;
  endDate: string;
  billInJanuary: boolean;
  billInFebruary: boolean;
  billInMarch: boolean;
  billInApril: boolean;
  billInMay: boolean;
  billInJune: boolean;
  billInJuly: boolean;
  billInAugust: boolean;
  billInSeptember: boolean;
  billInOctober: boolean;
  billInNovember: boolean;
  billInDecember: boolean;
  lastBilledAt: string;
  occurrencesToBill: number;
  occurrencesBilledToDate: number;
  totalAmountToBill: number;
  amountBilledToDate: number

  constructor(object: Object = {}) {
    let properties = [
      'accountNumber',
      'accountTypeId',
      'accountType',
      'accountTypeDescription',
      'accountStatus',
      'customerNumber',
      'customerName',
      'customerStatus',
      'chargeTypeId',
      'chargeCode',
      'chargeDescription',
      'rcId',
      'rcAccountId',
      'additionalInformation',
      'recurring',
      'parentReceivableChargeId',
      'chargeStatus',
      'quantity',
      'amount',
      'createdAt',
      'rccId',
      'rccAccountId',
      'recurringCriteriaStatus',
      'startDate',
      'endDate',
      'billInJanuary',
      'billInFebruary',
      'billInMarch',
      'billInApril',
      'billInMay',
      'billInJune',
      'billInJuly',
      'billInAugust',
      'billInSeptember',
      'billInOctober',
      'billInNovember',
      'billInDecember',
      'lastBilledAt',
      'occurrencesToBill',
      'occurrencesBilledToDate',
      'totalAmountToBill',
      'amountBilledToDate'
    ];
    super(object, properties);
  }
}
