import { DataType } from './dataType';

export class FinancialsBudgetAccountGroup extends DataType {
	financialsFundId: number;
	financialsDepartmentSegId: number;
	financialsDivisionSegId: number;
	fundVal: string;
	deptVal: string;
	divVal: string;
	financialsEntityId: number;
	accountType: string;
	groupName: string;
	groupId: number;
	groupSequence: number;
	subGroupName: string;
	subGroupId: number;
	subGroupSequence: number;
	fundNumber: string;
	fundDescription: string;
	deptNumber: string;
	deptDescription: string;
	divNumber: string;
	divDescription: string;
	mtdBalance: number;
	mtdBudget: number;
	ytdBalance: number;
	ytdBudget: number;
	annualBudget: number;
	mtdAvail: number;
	ytdAvail: number;
	annualAvail: number;

	constructor(object: Object = {}) {
		const properties = [
			'financialsFundId',
			'financialsDepartmentSegId',	
			'financialsDivisionSegId',
			'fundVal',
			'deptVal',
			'divVal',,
			'financialsEntityId',,
			'accountType',
			'groupName',
			'groupId',
			'groupSequence',
			'subGroupName',
			'subGroupId',
			'subGroupSequence',
			'fundNumber',
			'fundDescription',
			'deptNumber',
			'deptDescription',
			'divNumber',
			'divDescription',
			'mtdBalance',
			'mtdBudget',
			'ytdBalance',
			'ytdBudget',
			'annualBudget',
			'mtdAvail',
			'ytdAvail',
			'annualAvail'
		];
		super(object, properties);
	}
}
