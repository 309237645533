import { DataType } from './dataType';
import { User } from './user';

export class FinancialsProjectType extends DataType {
	id: number;
	projectType: string;
	description: string;
	requireFundingType: boolean;
	requireFundingSource: boolean;
	requireFederalGrantId: boolean;
	requireStateGrantId: boolean;
	requireFirstMonthGrantFy: boolean;
	requireExpirationDate: boolean;
	financialsEntityId: number;
	status: string;
	updatedByUserId: number;
	udpatedByUser: User;
	createdAt: string;
	updatedAt: string;

	constructor(object: Object = {}) {
		const properties = [
							'id',
							'projectType',
							'description',
							'requireFundingType',
							'requireFundingSource',
							'requireFederalGrantId',
							'requireStateGrantId',
							'requireFirstMonthGrantFy',
							'requireExpirationDate',
							'financialsEntityId',
							'status',
							'updatedByUserId',
							'updatedByUser',
							'createdAt',
							'updatedAt'];
		super(object, properties);
	}
}
