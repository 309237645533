import { DataType } from './dataType';
import { FinancialsFunctionCategory } from './financialsFunctionCategory';
import { User } from './user';

export class FinancialsFunction extends DataType {
	id: number;
	functionNumber: string;
	description: string;
	status: string;
	financialsEntityId: number;
	financialsFunctionCategoryId: number;
	functionCategory: FinancialsFunctionCategory;
	updatedByUserId: number;
	updatedByUser: User;
	createdAt: string;
	updatedAt: string;

	constructor(object: Object = {}) {
		const properties = [
			'id',
			'functionNumber',
			'description',
			'status',
			'financialsEntityId',
			'financialsFunctionCategoryId',
			'functionCategory',
			'updatedByUserId',
			'updatedByUser',
			'createdAt',
			'updatedAt',
		];
		super(object, properties);
	}
}
