
import { DataType } from './dataType';


export class FinancialsUnpaidInvoiceStatistic extends DataType {
	financialsEntityId: number;
	postedCount: number;
	scheduledCount: number;
	pendingCount: number;
	postedTotal: number;
	scheduledTotal: number;
	pendingTotal: number;
	sevenOrLessCount: number;
	eightOrMoreCount: number;
	pastDueCount: number;
	sevenOrLessTotal: number;
	eightOrMoreTotal: number;
	pastDueTotal: number;
	oneTimeVendorCount: number;
	standardVendorCount: number;
	oneTimeVendorTotal: number;
	standardVendorTotal: number;
	unpaidCount: number;
	unpaidTotal: number;


	constructor(object: Object = {}) {
		const properties = [
			'financialsEntityId',
			'postedCount',
			'scheduledCount',
			'pendingCount',
			'postedTotal',
			'scheduledTotal',
			'pendingTotal',
			'sevenOrLessCount',
			'eightOrMoreCount',
			'pastDueCount',
			'sevenOrLessTotal',
			'eightOrMoreTotal',
			'pastDueTotal',
			'oneTimeVendorCount',
			'standardVendorCount',
			'oneTimeVendorTotal',
			'standardVendorTotal',
			'unpaidCount',
			'unpaidTotal',];
		super(object, properties);
	}
}
