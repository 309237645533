import { DataType } from './dataType';
import { User } from './user';
import { BankAccount } from './bankAccount';
import { TemporaryFile } from './temporaryFile';


export class FinancialsPaymentReconFile extends DataType {
	id: number;
	bankAccountId: number;
	bankAccount: BankAccount
	throughDate: string;
	downloadedAt: string;
	status: string;
	reconciledByUserId: number;
	reconciledByUser: User;
	updatedByUserId: number;
	updatedByUser: User;
	createdAt: string;
	updatedAt: string;
	temporaryFileId: number;
	temporaryFile: TemporaryFile;
	

	constructor(object: Object = {}) {
		const properties = [
			'id',
			'bankAccountId',
			'bankAccount',
			'throughDate',
			'downloadedAt',
			'status',
			'reconciledByUserId',
			'reconciledByUser',
			'updatedByUserId',
			'updatedByUser',
			'createdAt',
			'updatedAt',
			'temporaryFileId',
			'temporaryFile',
		];
		super(object, properties);
	}
}
