import { DataType } from './dataType';
import { Address } from './address';
import { User } from './user';

export class Entity extends DataType {
	id: number;
	name: string;
	description: string;
	numberOfFiscalPeriods: number;
	fiscalYearStartMonth: number;
	address: Address;
	updatedByUserId: number;
	updatedByUser: User;
	createdAt: string;
	updatedAt: string;

	constructor(object: Object = {}) {
		const properties = [
			'id',
			'name',
			'description',
			'numberOfFiscalPeriods',
			'fiscalYearStartMonth',
			'address',
			'updatedByUserId',
			'udpatedByUser',
			'createdAt',
			'updatedAt'
		];
		super(object, properties);

		if (!object['address']) {
			this.address = new Address();
		}
	}
}
