import { DataType } from './dataType';

export class RolePermission extends DataType {
	roleId: number;
	role: string;
	application: string;
	applicationId: number;
	permissionGroup: string;
	permissionGroupId: number;
	permission: string;
	permissionId: number;

	constructor(object: Object = {}) {
		const properties = [
			'roleId',
			'role',
			'application',
			'applicationId',
			'permissionGroup',
			'permissionGroupId',
			'permission',
			'permissionId'
		];
		super(object, properties);
	}
}
