import { DataType } from './dataType';
import { User } from './user';

export class FinancialsProjectGroup extends DataType {
	id: number;
	groupName: string;
	description: string;
	financialsEntityId: number;
	status: string;
	updatedByUserId: number;
	udpatedByUser: User;
	createdAt: string;
	updatedAt: string;

	constructor(object: Object = {}) {
		const properties = [
							'id',
							'groupName',
							'description',
							'financialsEntityId',
							'status',
							'updatedByUserId',
							'updatedByUser',
							'createdAt',
							'updatedAt'];
		super(object, properties);
	}
}
