import { DataType } from './dataType';
import { User } from './user';
import { FinancialsPeriod } from './financialsPeriod';
import { FinancialsFiscalYear } from './financialsFiscalYear';
import { FinancialsPayment } from './financialsPayment';
import { FinancialsInvoice } from './financialsInvoice';
import { BankAccount } from './bankAccount';

export class FinancialsJournal extends DataType {
	id: number;
	journalNumber: string;
	journalType: string;
	description: string;
	useDefault: boolean;
	effectiveDate: string;
	transactionDate: string;
	postedDate: string;
	autoReversingDate: string;
	periodVal: number;
	financialsPeriodId: number;
	period: FinancialsPeriod;
	fyVal: number;
	financialsFiscalYearId: number;
	fiscalYear: FinancialsFiscalYear;
	financialsPaymentId: number;
	payment: FinancialsPayment;
	paymentNumber: number;
	financialsInvoiceId: number;
	invoice: FinancialsInvoice;
	invoiceVal: number;
	status: string;
	markForDeletion: boolean;
	transactionSource: string;
	financialsEntityId: number;
	updatedByUserId: number;
	updatedByUser: User;
	createdAt: string;
	updatedAt: string;
	createdByUserId: number;
	createdByUser: User;
	postedByUserId: number;
	postedByUser: User;
	reversalPeriodId: number;
	reversalPeriod: FinancialsPeriod;
	locked: boolean;
	bankAccountId: number;
	bankAccount: BankAccount;

	constructor(object: Object = {}) {
		const properties = [
			'id',
			'journalNumber',
			'journalType',
			'description',
			'useDefault',
			'effectiveDate',
			'transactionDate',
			'postedDate',
			'autoReversingDate',
			'periodVal',
			'financialsPeriodId',
			'period',
			'fyVal',
			'financialsFiscalYearId',
			'fiscalYear',
			'financialsPaymentId',
			'payment',
			'paymentNumber',
			'financialsInvoiceId',
			'invoice',
			'invoiceVal',
			'status',
			'markForDeletion',
			'transactionSource',
			'financialsEntityId',
			'updatedByUserId',
			'updatedByUser',
			'createdAt',
			'updatedAt',
			'createdByUserId',
			'createdByUser',
			'postedByUserId',
			'postedByUser',
			'reversalPeriodId',
			'reversalPeriod',
			'locked',
			'bankAccountId',
			'bankAccount'
		];
		super(object, properties);
	}
}
