import { NgModule, ModuleWithProviders, Optional } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { DataService } from './services/services';
import { MockDataService } from './services/services';
import { ApiService } from './services/services';
import { CurrentTownService } from './services/services';

import { ApplicationsResource } from './resources/applications.resource';
import { UsersResource } from './resources/users.resource';
import { UserRolesResource } from './resources/userRoles.resource';
import { UserCitiesResource } from './resources/userCities.resource';
import { UserPermissionsResource } from './resources/userPermissions.resource';
import { RolePermissionsResource } from './resources/rolePermissions.resource';
import { AccessTokenResource } from './resources/accessToken.resource';
import { RolesResource } from './resources/roles.resource';
import { PermissionsResource } from './resources/permissions.resource';
import { ScopesResource } from './resources/scopes.resource';
import { CashDrawersResource } from './resources/cashDrawers.resource';
import { ReceiptsResource } from './resources/receipts.resource';
import { ReceiptTendersResource } from './resources/receiptTenders.resource';
import { ReceiptTendersListResource } from './resources/receiptTendersList.resource';
import { PaymentsResource } from './resources/payments.resource';
import { ItemsResource } from './resources/items.resource';
import { DepositsResource } from './resources/deposits.resource';
import { DepositBatchListResource } from './resources/depositBatchList.resource';
import { GeneralLedgerEntriesResource } from './resources/generalLedgerEntries.resource';
import { GeneralLedgerEntryItemsResource } from './resources/generalLedgerEntryItems.resource';
import { BatchesResource } from './resources/batches.resource';
import { BatcheReconciliationResource } from './resources/batchReconciliation.resource';
import { ReconciliationListResource } from './resources/reconciliationList.resource';
import { BatchReceiptsResource } from './resources/batchReceipts.resource';
import { ReceiptItemsResource } from './resources/receiptItems.resource';
import { ReceiptLineListResource } from './resources/receiptLineList.resource';
import { ContactsResource } from './resources/contacts.resource';
import { ContactTypesResource } from './resources/contactTypes.resource';
import { FeesResource } from './resources/fees.resource';
import { BusinessesResource } from './resources/businesses.resource';
import { BusinessContactsResource } from './resources/businessContacts.resource';
import { BusinessLicenseListResource } from './resources/businessLicenseList.resource';
import { OwnershipTypesResource } from './resources/ownershipTypes.resource';
import { LicensesResource } from './resources/licenses.resource';
import { BusinessLicensesResource } from './resources/businessLicense.resource';
import { LicenseTypesResource } from './resources/licenseTypes.resource';
import { LicenseTypeFeesResource } from './resources/licenseTypeFees.resource';
import { LicenseTypeDocumentTypesResource } from './resources/licenseTypeDocumentTypes.resource';
import { LicenseFeesResource } from './resources/licenseFees.resource';
import { CitiesResource } from './resources/cities.resource';
import { CityApplicationsResource } from './resources/cityApplications.resource';
import { LocationsResource } from './resources/locations.resource';
import { ZoningCodesResource } from './resources/zoningCodes.resource';
import { GarageSalePermitsResource } from './resources/garageSalePermits.resource';
import { PermitFeesResource } from './resources/permitFees.resource';
import { PermitPartiesResource } from './resources/permitParties.resource';
import { PermitDatesResource } from './resources/permitDates.resource';
import { PaymentSourcesResource } from './resources/paymentSources.resource';
import { TimeZonesResource } from './resources/timeZones.resource';
import { StatesResource } from './resources/states.resource';
import { TendersResource } from './resources/tenders.resource';
import { AdjustmentsResource } from './resources/adjustments.resource';
import { NaicsResource } from './resources/naics.resource';
import { CategoriesResource } from './resources/categories.resource';
import { BanksResource } from './resources/banks.resource';
import { BankAccountsResource } from './resources/bankAccounts.resource';
import { KeyToCityResource } from './resources/keyToCity.resource';
import { LicenseRenewalSettingsResource } from './resources/licenseRenewalSettings.resource';
import { LicenseTypeRenewalsResource } from './resources/licenseTypeRenewals.resource';
import { LicenseTypeRenewalHistoryResource } from './resources/licenseTypeRenewalHistory.resource';
import { CountriesResource } from './resources/countries.resource';
import { MerchantSettingsResource } from './resources/merchantSettings.resource';
import { TenderFeesResource } from './resources/tenderFees.resource';
import { ReceiptFeesResource } from './resources/receiptFees.resource';
import { AttendeeRolesResource } from './resources/attendeeRoles.resource';
import { MinutesTemplatesResource } from './resources/minutesTemplates.resource';
import { MeetingItemTypesResource } from './resources/meetingItemTypes.resource';
import { MeetingTypesResource } from './resources/meetingTypes.resource';
import { MeetingTypeOutlinesResource } from './resources/meetingTypeOutlines.resource';
import { MeetingTypeAttendeesResource } from './resources/meetingTypeAttendees.resource';
import { DocumentTypesResource } from './resources/documentTypes.resource';
import { BusinessCertificationTypesResource } from './resources/businessCertificationTypes.resource';
import { ContactCertificationTypesResource } from './resources/contactCertificationTypes.resource';
import { OpportunityTypesResource } from './resources/opportunityTypes.resource';
import { OpportunityTypeDocumentTypesResource } from './resources/opportunityTypeDocumentTypes.resource';
import { OpportunitiesResource } from './resources/opportunities.resource';
import { EventTypesResource } from './resources/eventTypes.resource';
import { EventTypeSettingsResource } from './resources/eventTypeSettings.resource';
import { EventsResource } from './resources/events.resource';
import { AttachmentsResource } from './resources/attachments.resource';
import { PetsResource } from './resources/pets.resource';
import { PetLicensesResource } from './resources/petLicenses.resource';
import { OpportunityAttachmentsResource } from './resources/opportunityAttachments.resource';
import { BusinessAttachmentsResource } from './resources/businessAttachments.resource';
import { JobsResource } from './resources/jobs.resource';
import { JobOutputsResource } from './resources/jobOutputs.resource';
import { CustomersResource } from './resources/customers.resource';
import { CustomerAttachmentsResource } from './resources/customerAttachments.resource';
import { ReceivableAccountTypesResource } from './resources/receivableAccountTypes.recource';
import { ReceivableAccountsResource } from './resources/receivableAccounts.resource';
import { ReceivableAccountAgedSummariesResource } from './resources/receivableAccountAgedSummaries.resource';
import { ReceivableAccountAttachmentsResource } from './resources/receivableAccountAttachments.resource';
import { ChargeTypesResource } from './resources/chargeTypes.resource';
import { ChargeTypeAmountsResource } from './resources/chargeTypeAmounts.resource';
import { AgingGroupsResource } from './resources/agingGroups.resource';
import { AgingStepsResource } from './resources/agingSteps.resource';
import { ReceivableChargesResource } from './resources/receivableCharges.resource';
import { ReceivablePendingChargesResource } from './resources/receivablePendingCharges.resource';
import { ReceivableAdjustmentsResource } from './resources/receivableAdjustments.resource';
import { RecurringChargeCriteriaResource } from './resources/recurringChargeCriteria.resource';
import { ReceivableBillingHistoriesResource } from './resources/receivableBillingHistories.resource';
import { ReceivableBillingPromptsResource } from './resources/receivableBillingPrompts.resource';
import { ReceivableBillingSummariesResource } from './resources/receivableBillingSummaries.resource';
import { ReceivableBillingDetailsResource } from './resources/receivableBillingDetails.resource';
import { ReceivablePaymentsResource } from './resources/receivablePayments.resource';
import { ReceivablePaymentDetailsResource } from './resources/receivablePaymentDetails.resource';
import { ReceivableTransactionsResource } from './resources/receivableTransactions.resource';
import { ReceivableChargeAgedSummariesResource } from './resources/receivableChargeAgedSummaries.resource';
import { ReceivableChargeAgedSummaryDetailsResource } from './resources/receivableChargeAgedSummaryDetails.resource';
import { ReceivableStatementSettingsResource } from './resources/receivableStatementSettings.resource';
import { ReceivableOverpaymentSettingsResource } from './resources/receivableOverpaymentSettings.resource';
import { PaymentEntrySettingsResource } from './resources/paymentEntrySettings.resource';
import { RpcResource } from './resources/rpc.resource';

import { AppSettings } from './settings';
import { LicensePenaltiesResource } from './resources/licensePenalties.resource';
import { LicensePenaltyCriteriaResource } from './resources/licensePenaltyCriteria.resource';
import { BusinessLicensePenaltyHistoriesResource } from './resources/businessLicensePenaltyHistories.resource';
import { LicenseFeeListResource } from './resources/licenseFeeList.resource';
import { LicenseCertificateSettingsResource } from './resources/licenseCertificateSettings.resource';

import { FinancialsProjectsResource } from './resources/financialsProjects.resource';
import { FinancialsProjectAttachmentsResource } from './resources/financialsProjectAttachments.resource';
import { FinancialsProjectGroupsResource } from './resources/FinancialsProjectGroups.resource';
import { FinancialsProjectTypesResource } from './resources/FinancialsProjectTypes.resource';
import { FinancialsAccountGroupsResource } from './resources/FinancialsAccountGroups.resource';
import { EntitiesResource } from './resources/entities.resource';
import { FinancialsAccountConfigsResource } from './resources/financialsAccountConfigs.resource';
import { FinancialsAccountSubGroupsResource } from './resources/FinancialsAccountSubGroups.resource';
import { FinancialsFunctionCategoriesResource } from './resources/FinancialsFunctionCategories.resource';
import { FinancialsFundTypesResource } from './resources/FinancialsFundTypes.resource';
import { FinancialsFundsResource } from './resources/FinancialsFunds.resource';
import { FinancialsFunctionsResource } from './resources/FinancialsFunctions.resource';
import { FinancialsDepartmentSegsResource } from './resources/FinancialsDepartmentSegs.resource';
import { FinancialsDivisionSegsResource } from './resources/FinancialsDivisionSegs.resource';
import { FinancialsAccountRulesResource } from './resources/FinancialsAccountRules.resource';
import { FinancialsAccountObjectsResource } from './resources/FinancialsAccountObjects.resource';
import { FinancialsAccountsResource } from './resources/FinancialsAccounts.resource';
import { FinancialsAccountCrossReferencesResource } from './resources/FinancialsAccountCrossReferences.resource';
import { FinancialsAccountBalancesResource } from './resources/financialsAccountBalances.resource';
import { FinancialsBudgetAccountsResource } from './resources/financialsBudgetAccounts.resource';
import { FinancialsBudgetAccountGroupsResource } from './resources/financialsBudgetAccountGroups.resource';
import { FinancialsAccountsByObjectResource } from './resources/FinancialsAccountsByObject.resource';
import { FinancialsAccountsByFundResource } from './resources/FinancialsAccountsByFund.resource';
import { FinancialsAccountsByDepartmentResource } from './resources/FinancialsAccountsByDepartment.resource';
import { FinancialsAccountsByDivisionResource } from './resources/FinancialsAccountsByDivision.resource';
import { FinancialsFiscalYearsResource } from './resources/FinancialsFiscalYears.resource';
import { FinancialsPeriodsResource } from './resources/FinancialsPeriods.resource';
import { FinancialsJournalsResource } from './resources/FinancialsJournals.resource';
import { FinancialsJournalAttachmentsResource } from './resources/FinancialsJournalAttachments.resource';
import { FinancialsTransactionsResource } from './resources/FinancialsTransactions.resource';
import { FinancialsTransactionInvoiceDetailsResource } from './resources/FinancialsTransactionInvoiceDetails.resource';
import { FinancialsPooledCashConfigsResource } from './resources/FinancialsPooledCashConfigs.resource';
import { FinancialsPooledCashFundEquitiesResource } from './resources/FinancialsPooledCashFundEquities.resource';
import { FinancialsDueToDueFromFundsResource } from './resources/financialsDueToDueFromFunds.resource';
import { FinancialsPooledCashSettingsResource } from './resources/financialsPooledCashSettings.resource';
import { FinancialsCashManagementOptionsResource } from './resources/financialsCashManagementOptions.resource';
import { FinancialsReservedAccountsSettingsResource } from './resources/financialsReservedAccountsSettings.resource';
import { FinancialsVendorsResource } from './resources/financialsVendors.resource';
import { FinancialsVendorAttachmentsResource } from './resources/financialsVendorAttachments.resource';
import { FinancialsOneTimeVendorsResource } from './resources/financialsOneTimeVendors.resource';
import { FinancialsTaxFormPayeeNamesResource } from './resources/financialsTaxFormPayeeNames.resource';
import { PermissionGroupsResource } from './resources/permissionGroups.resource';
import { RolePermissionGroupsResource } from './resources/rolePermissionGroups.resource';
import { RoleUsersResource } from './resources/roleUsers.resource';
import { MeasurementUnitsResource } from './resources/measurementUnits.resource';
import { FinancialsInvoicesResource } from './resources/FinancialsInvoices.resource';
import { FinancialsInvoiceAttachmentsResource } from './resources/financialsInvoiceAttachments.resource';
import { FinancialsInvoiceLineItemsResource } from './resources/FinancialsInvoiceLineItems.resource';
import { FinancialsUnpaidInvoiceStatisticsResource } from './resources/financialsUnpaidInvoiceStatistics.resource';
import { FinancialsPaidInvoiceStatisticsResource } from './resources/financialsPaidInvoiceStatistics.resource';
import { FinancialsPaymentsResource } from './resources/financialsPayments.resource';
import { FinancialsPaymentRunsResource } from './resources/financialsPaymentRuns.resource';
import { FinancialsPaymentTransactionDetailsResource } from './resources/financialsPaymentTransactionDetails.resource';
import { FinancialsAchNotificationSettingsResource } from './resources/financialsAchNotificationSettings.resource';
import { FinancialsPendingPaymentReportOptionsResource } from './resources/financialsPendingPaymentReportOptions.resource';
import { FinancialsWarrantProcessingResource } from './resources/financialsWarrantProcessing.resource';
import { FinancialsPendingPaymentApprovalSignaturesResource } from './resources/FinancialsPendingPaymentApprovalSignatures.resource';
import { FinancialsWarrantsResource } from './resources/FinancialsWarrants.resource';
import { EmailDeliveryLogsResource } from './resources/emailDeliveryLogs.resource';
import { FinancialsProjectTransactionsResource } from './resources/financialsProjectTransactions.resource';
import { CheckReconFormatsResource } from './resources/checkReconFormats.resource';
import { FinancialsPaymentReconFilesResource } from './resources/financialsPaymentReconFiles.resource';
import { FinancialsPaymentReconDetailsResource } from './resources/financialsPaymentReconDetails.resource';
import { TemporaryFilesResource } from './resources/temporaryFiles.resource';
@NgModule({
	imports: [HttpClientModule],
})
export class ThorApiModule {
	static forRoot(config: any): ModuleWithProviders {
		return {
			ngModule: ThorApiModule,
			providers: [
				{ provide: 'config', useValue: config },
				AppSettings,
				DataService,
				MockDataService,
				ApiService,
				CurrentTownService,

				ApplicationsResource,
				UsersResource,
				UserRolesResource,
				UserCitiesResource,
				UserPermissionsResource,
				RolePermissionsResource,
				AccessTokenResource,
				RolesResource,
				PermissionsResource,
				ScopesResource,
				CashDrawersResource,
				ReceiptsResource,
				ReceiptTendersResource,
				ReceiptTendersListResource,
				PaymentsResource,
				ItemsResource,
				DepositsResource,
				DepositBatchListResource,
				GeneralLedgerEntriesResource,
				GeneralLedgerEntryItemsResource,
				BatchesResource,
				BatcheReconciliationResource,
				ReconciliationListResource,
				BatchReceiptsResource,
				ReceiptItemsResource,
				ReceiptLineListResource,
				ContactsResource,
				CustomersResource,
				CustomerAttachmentsResource,
				ContactTypesResource,
				FeesResource,
				BusinessesResource,
				BusinessContactsResource,
				BusinessLicenseListResource,
				OwnershipTypesResource,
				LicensesResource,
				BusinessLicensesResource,
				LicenseTypesResource,
				LicenseTypeFeesResource,
				LicenseTypeDocumentTypesResource,
				LicenseFeesResource,
				CitiesResource,
				CityApplicationsResource,
				LocationsResource,
				ZoningCodesResource,
				GarageSalePermitsResource,
				PermitFeesResource,
				PermitPartiesResource,
				PermitDatesResource,
				PaymentSourcesResource,
				TimeZonesResource,
				StatesResource,
				TendersResource,
				AdjustmentsResource,
				NaicsResource,
				CategoriesResource,
				BanksResource,
				BankAccountsResource,
				KeyToCityResource,
				LicenseRenewalSettingsResource,
				LicenseTypeRenewalsResource,
				LicenseTypeRenewalHistoryResource,
				CountriesResource,
				MerchantSettingsResource,
				TenderFeesResource,
				ReceiptFeesResource,
				AttendeeRolesResource,
				MinutesTemplatesResource,
				MeetingItemTypesResource,
				MeetingTypesResource,
				MeetingTypeOutlinesResource,
				MeetingTypeAttendeesResource,
				DocumentTypesResource,
				BusinessCertificationTypesResource,
				ContactCertificationTypesResource,
				OpportunityTypesResource,
				OpportunityTypeDocumentTypesResource,
				OpportunitiesResource,
				EventTypesResource,
				EventTypeSettingsResource,
				EventsResource,
				AttachmentsResource,
				OpportunityAttachmentsResource,
				BusinessAttachmentsResource,
				PetsResource,
				PetLicensesResource,
				JobsResource,
				JobOutputsResource,
				ReceivableAccountTypesResource,
				ReceivableAccountsResource,
				ReceivableAccountAgedSummariesResource,
				ReceivableChargeAgedSummariesResource,
				ReceivableAccountAttachmentsResource,
				ChargeTypesResource,
				ChargeTypeAmountsResource,
				AgingGroupsResource,
				AgingStepsResource,
				ReceivableChargesResource,
				ReceivablePendingChargesResource,
				ReceivableAdjustmentsResource,
				RecurringChargeCriteriaResource,
				ReceivableBillingHistoriesResource,
				ReceivableBillingPromptsResource,
				ReceivableBillingSummariesResource,
				ReceivableBillingDetailsResource,
				ReceivablePaymentsResource,
				ReceivablePaymentDetailsResource,
				ReceivableTransactionsResource,
				ReceivableChargeAgedSummariesResource,
				ReceivableChargeAgedSummaryDetailsResource,
				ReceivableStatementSettingsResource,
				ReceivableOverpaymentSettingsResource,
				PaymentEntrySettingsResource,
				RpcResource,
				LicensePenaltiesResource,
				LicensePenaltyCriteriaResource,
				BusinessLicensePenaltyHistoriesResource,
				LicenseFeeListResource,
				LicenseCertificateSettingsResource,

				FinancialsProjectsResource,
				FinancialsProjectAttachmentsResource,
				FinancialsProjectGroupsResource,
				FinancialsProjectTypesResource,
				FinancialsAccountGroupsResource,
				EntitiesResource,
				FinancialsAccountConfigsResource,
				FinancialsAccountSubGroupsResource,
				FinancialsFunctionCategoriesResource,
				FinancialsFundTypesResource,
				FinancialsFundsResource,
				FinancialsFunctionsResource,
				FinancialsDepartmentSegsResource,
				FinancialsDivisionSegsResource,
				FinancialsAccountRulesResource,
				FinancialsAccountObjectsResource,
				FinancialsAccountsResource,
				FinancialsAccountCrossReferencesResource,
				FinancialsBudgetAccountsResource,
				FinancialsBudgetAccountGroupsResource,
				FinancialsAccountBalancesResource,
				FinancialsAccountsByObjectResource,
				FinancialsAccountsByFundResource,
				FinancialsAccountsByDepartmentResource,
				FinancialsAccountsByDivisionResource,
				FinancialsFiscalYearsResource,
				FinancialsPeriodsResource,
				FinancialsJournalsResource,
				FinancialsJournalAttachmentsResource,
				FinancialsTransactionsResource,
				FinancialsProjectTransactionsResource,
				FinancialsTransactionInvoiceDetailsResource,
				FinancialsPooledCashConfigsResource,
				FinancialsPooledCashFundEquitiesResource,
				FinancialsDueToDueFromFundsResource,
				FinancialsPooledCashSettingsResource,
				FinancialsCashManagementOptionsResource,
				FinancialsReservedAccountsSettingsResource,
				FinancialsVendorsResource,
				FinancialsVendorAttachmentsResource,
				FinancialsOneTimeVendorsResource,
				FinancialsTaxFormPayeeNamesResource,

				PermissionGroupsResource,
				RolePermissionGroupsResource,
				RoleUsersResource,

				MeasurementUnitsResource,
				FinancialsInvoicesResource,
				FinancialsInvoiceAttachmentsResource,
				FinancialsInvoiceLineItemsResource,
				FinancialsUnpaidInvoiceStatisticsResource,
				FinancialsPaidInvoiceStatisticsResource,
				FinancialsPaymentsResource,
				FinancialsPaymentRunsResource,
				FinancialsPaymentTransactionDetailsResource,
				FinancialsAchNotificationSettingsResource,
				FinancialsPendingPaymentReportOptionsResource,
				FinancialsWarrantProcessingResource,
				FinancialsPendingPaymentApprovalSignaturesResource,
				FinancialsWarrantsResource,
				EmailDeliveryLogsResource,

				CheckReconFormatsResource,
				FinancialsPaymentReconFilesResource,
				FinancialsPaymentReconDetailsResource,
				TemporaryFilesResource

			],
		};
	}
}
