import { DataType } from './dataType';
import { User } from './user';
import { FinancialsPaymentReconFile } from './financialsPaymentReconFile';
import { FinancialsPayment } from './financialsPayment';

export class FinancialsPaymentReconDetail extends DataType {
	id: number;
	financialsPaymentReconFileId: number;
	paymentReconFile: FinancialsPaymentReconFile;
	financialsEntityId: number;
	financialsPaymentId: number;
	payment: FinancialsPayment;
	paymentNumber: number;
	paymentAmount: number;
	dateCleared: string;
	discrepancy: number;
	comment: string;
	status: string;
	updatedByUserId: number;
	updatedByUser: User;
	createdAt: string;
	updatedAt: string;

	constructor(object: Object = {}) {
		const properties = [
			'id',
			'financialsPaymentReconFileId',
			'paymentReconFile',
			'financialsEntityId',
			'financialsPaymentId',
			'payment',
			'paymentNumber',
			'paymentAmount',
			'dateCleared',
			'discrepancy',
			'comment',
			'status',
			'updatedByUserId',
			'updatedByUser',
			'createdAt',
			'updatedAt',
		];
		super(object, properties);
	}
}
