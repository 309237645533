import { DataType } from './dataType';

export class FinancialsPendingPaymentReportOptions extends DataType {
	includeSummaryPage: boolean;
	includeApprovalSignatures: boolean;
	numberOfSignatures: number;

	constructor(object: Object = {}){ 
		var properties = [
							'includeSummaryPage',
							'includeApprovalSignatures',
							'numberOfSignatures'
						];
		super(object, properties);

	}
}