import { DataType } from "./dataType";
import { FinancialsVendor } from './financialsVendor';
import { Attachment } from "./attachment";
import { User } from "./user";

export class FinancialsVendorAttachment extends DataType {
  id: number;
  financialsVendorId: number;
  vendor: FinancialsVendor;
  financialsEntityId: number
  attachmentId: number;
  attachment: Attachment;
  expirationDate: string;
  createdByUserId: number;
  createByUser: User;
  createdAt: string;
  updatedAt: string;

  constructor(object: Object = {}) {
    var properties = [
      "id",
      "financialsVendorId",
      "vendor",
      "financialsEntityId",
      "attachmentId",
      "attachment",
      "expirationDate",
      "createdByUserId",
      "createByUser",
      "createdAt",
      "updatedAt",
    ];
    super(object, properties);
  }
}
