import { DataType } from './dataType';
import { FinancialsAccountRule } from './financialsAccountRule';
import { User } from './user';

export class FinancialsBudgetAccount extends DataType {
	id: number;
	accountNumber: string;
	financialsAccountObjectId: number;
	financialsFundId: number;
	financialsDepartmentSegId: number;
	financialsDivisionSegId: number;
	fundVal: string;
	deptVal: string;
	divVal: string;
	objVal: string;
	status: string;
	fromDate: string;
	toDate: string;
	financialsEntityId: number;
	updatedByUserId: number;
	updatedByUser: User;
	oldAccountNumber: string;
	createdAt: string;
	updatedAt: string;
	accountType: string;
	objectDescription: string;
	objectNumber: string;
	financialsAccountruleId: number;
	accountRule: FinancialsAccountRule;
	groupName: string;
	groupId: number;
	groupSequence: number;
	subGroupName: string;
	subGroupId: number;
	subGroupSequence: number;
	fundNumber: string;
	fundDescription: string;
	deptNumber: string;
	deptDescription: string;
	divNumber: string;
	divDescription: string;
	mtdBalance: number;
	mtdBudget: number;
	ytdBalance: number;
	ytdBudget: number;
	annualBudget: number;
	mtdAvail: number;
	ytdAvail: number;
	annualAvail: number;

	constructor(object: Object = {}) {
		const properties = [
			'id',
			'accountNumber',
			'financialsAccountObjectId',
			'financialsFundId',
			'financialsDepartmentSegId',	
			'financialsDivisionSegId',
			'fundVal',
			'deptVal',
			'divVal',
			'objVal',
			'status',
			'fromDate',
			'toDate',
			'financialsEntityId',
			'updatedByUserId',
			'updatedByUser',
			'oldAccountNumber',
			'createdAt',
			'updatedAt',
			'accountType',
			'objectDescription',
			'objectNumber',
			'financialsAccountruleId',
			'accountRule',
			'groupName',
			'groupId',
			'groupSequence',
			'subGroupName',
			'subGroupId',
			'subGroupSequence',
			'fundNumber',
			'fundDescription',
			'deptNumber',
			'deptDescription',
			'divNumber',
			'divDescription',
			'mtdBalance',
			'mtdBudget',
			'ytdBalance',
			'ytdBudget',
			'annualBudget',
			'mtdAvail',
			'ytdAvail',
			'annualAvail'
		];
		super(object, properties);
	}
}
