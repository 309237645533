import { DataType } from './dataType';
import { BankAccount } from './bankAccount';
import { User } from "./user";

export class CheckReconFormat extends DataType {
	id: number;
	bankAccountId: number;
	bankAccount: BankAccount;
	checkNumberColumn: number;
	checkAmountColumn: number;
	dateClearedColumn: number;
	totalNumberOfColumns: number;
	hasHeader: boolean;
	dateClearedFormat: string;
	updatedByUserId: number;
	updatedByUser: User;
	createdAt: string;
	updatedAt: string;

	constructor(object: Object = {}){ 
		var properties = [
			"id",
			'bankAccountId',
			'bankAccount',
			'checkNumberColumn',
			'checkAmountColumn',
			'dateClearedColumn',
			'totalNumberOfColumns',
			'hasHeader',
			'dateClearedFormat',
			'updatedByUserId',
			'updatedByUser',
			'createdAt',
			'updatedAt'
		];
		super(object, properties);
	}
}