import { Injectable } from '@angular/core';

import { ResourceService, DataService } from '../services/services';

@Injectable()
export class RolePermissionsResource extends ResourceService {
	constructor(public dataService: DataService) {
		super(dataService, 'rolepermissions');
	}
}
