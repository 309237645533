import { Injectable } from '@angular/core';

import {
	ResourceService,
	DataService
} from '../services/services';

@Injectable()
export class FinancialsProjectTypesResource extends ResourceService {

	constructor(public dataService: DataService) {
		super(dataService, 'financialsprojecttypes');
	}
}
