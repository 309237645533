import { DataType } from './dataType';
import { User } from './user';
import { Role } from './role';

export class RoleUser extends DataType {
	id: number;
	roleId: number;
	role: Role;
	userId: number;
	user: User;
  createdAt: string;
  updatedAt: string;


	constructor(object: Object = {}){ 
		var properties = [
			'id',
			'roleId',
			'role',
			'userId',
			'user',
			'createdAt',
			'updatedAt'	
		];
		super(object, properties);
	}
}