import { DataType } from './dataType';
import { ChargeType } from './chargeType';

export class ReceivableChargeAgedSummary extends DataType {
  chargeTypeId: number;
  chargeType: ChargeType;
  chargeCode: string;
  description: string;
  currentAmount: number;
  thirtyDayAmount: number;
  sixtyDayAmount: number;
  ninetyDayAmount: number;
  onetwentyDayAmount: number;
  pastDueAmount: number;
  totalAmountDue: number;

  constructor(object: Object = {}) {
    const properties = [
      'chargeTypeId',
      'chargeType',
      'chargeCode',
      'description',
      'currentAmount',
      'thirtyDayAmount',
      'sixtyDayAmount',
      'ninetyDayAmount',
      'onetwentyDayAmount',
      'pastDueAmount',
      'totalAmountDue'
    ];
    super(object, properties);
  }
}
