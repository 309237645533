import { DataType } from './dataType';
import { FinancialsFund } from './financialsFund';
import { FinancialsDepartmentSeg } from './financialsDepartmentSeg';
import { FinancialsDivisionSeg } from './financialsDivisionSeg';
import { FinancialsAccountObject } from './financialsAccountObject';
import { FinancialsProject } from './financialsProject';
import { FinancialsFunction } from './financialsFunction';
import { FinancialsAccount } from './financialsAccount';
import { FinancialsPeriod } from './financialsPeriod';
import { FinancialsFiscalYear } from './financialsFiscalYear';
import { FinancialsVendor } from './financialsVendor';
import { FinancialsOneTimeVendor } from './financialsOneTimeVendor';
import { FinancialsPaymentRun } from './financialsPaymentRun';
import { FinancialsTransaction } from './financialsTransaction';
import { Bank } from './bank';
import { User } from './user';

export class FinancialsPaymentTransactionDetail extends DataType {
	id: number;
	financialsPaymentRunId: number;
	paymentRun: FinancialsPaymentRun;
	financialsVendorId: number;
	vendor: FinancialsVendor;
	financialsOneTimeVendorId: number;
	oneTimeVendor: FinancialsOneTimeVendor;
	paymentDate: string;
	paymentMethod: string;
	paymentNumber: number;
	paymentAmount: number;
	eftBankAccountNumber: number;
	bankId: number;
	bank: Bank;
	effectiveDate: string;
	financialsEntityId: number;
	financialsPeriodId: number;
	period: FinancialsPeriod;
	financialsFiscalYearId: number;
	fiscalYear: FinancialsFiscalYear;
	periodVal: number;
	fyVal: number;
	status: string;
	dateVoided: string;
	voidedByUserId: number;
	voidedByUser: User;
	voidReason: string;
	dateReconciled: string;
	reconciledByUserId: number;
	reconciledByUser: User;
	updatedByUserId: number;
	updatedByUser: User;
	createdAt: string;
	updatedAt: string;
	paidVendorName: string;
	paidVendorNumber: number;
	miscVendor: boolean;
	vendorNumber: number;
	nameOnCheck: string;
	oneTimeName: string;
	oneTimeVendorNumber: number;
	transactionId: number;
	transaction: FinancialsTransaction;
	financialsAccountId: number;
	account: FinancialsAccount;
	financialsAccountObjectId: number;
	accountObject: FinancialsAccountObject;
	financialsFundId: number;
	fund: FinancialsFund;
	financialsDeparmentSegId: number;
	department:	FinancialsDepartmentSeg;
	financialsDivisionSegId: number;
	division: FinancialsDivisionSeg;
	financialsFunctionId: number;
	function: FinancialsFunction;
	financialsProjectId: number;
	project: FinancialsProject;
	accountVal: string;
	objVal: string;
	fundVal: string;
	deptVal: string;
	divVal: string;
	funcVal: string;
	projVal: string;
	description: string;
	isDebit: boolean;
	drCr: string;
	amount: number;
	debitAmount: number;
	creditAmount: number;

	constructor(object: Object = {}) {
		const properties = [
			'id',
			'financialsPaymentRunId',
			'paymentRun',
			'financialsVendorId',
			'vendor',
			'financialsOneTimeVendorId',
			'oneTimeVendor',
			'paymentDate',
			'paymentMethod',
			'paymentNumber',
			'paymentAmount',
			'eftBankAccountNumber',
			'bankId',
			'bank',
			'effectiveDate',
			'financialsEntityId',
			'financialsPeriodId',
			'period',
			'financialsFiscalYearId',
			'fiscalYear',
			'periodVal',
			'fyVal',
			'status',
			'dateVoided',
			'voidedByUserId',
			'voidedByUser',
			'voidReason',
			'dateReconciled',
			'reconciledByUserId',
			'reconciledByUser',
			'updatedByUserId',
			'updatedByUser',
			'createdAt',
			'updatedAt',
			'paidVendorName',
			'paidVendorNumber',
			'miscVendor',
			'vendorNumber',
			'nameOnCheck',
			'oneTimeName',
			'oneTimeVendorNumber',
			'transactionId',
			'transaction',
			'financialsAccountId',
			'account',
			'financialsAccountObjectId',
			'accountObject',
			'financialsFundId',
			'fund',
			'financialsDeparmentSegId',
			'department',
			'financialsDivisionSegId',
			'division',
			'financialsFunctionId',
			'function',
			'financialsProjectId',
			'project',
			'accountVal',
			'objVal',
			'fundVal',
			'deptVal',
			'divVal',
			'funcVal',
			'projVal',
			'description',
			'isDebit',
			'drCr',
			'amount',
			'debitAmount',
			'creditAmount',
		];
		super(object, properties);
	}
}
