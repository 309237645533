import { DataType } from './dataType';
import { User } from './user';
import { FinancialsAccountObject } from './financialsAccountObject';
import { BankAccount } from './bankAccount';


export class FinancialsPooledCashConfig extends DataType {
	id: number;
	bankAccountId: number;
	bankAccount: BankAccount;
	financialsEntityId: number;
	cashAccountObjectId: number;
	cashAccountObject: FinancialsAccountObject;
	pooledCashAccountObjectId: number;
	pooledCashAccountObject: FinancialsAccountObject;
	interestAccountObjectId: number;
	interestAccountObject: FinancialsAccountObject;
	updatedByUserId: number;
	updatedByUser: User;
	createdAt: string;
	updatedAt: string;

	constructor(object: Object = {}) {
		const properties = [
			'id',
			'bankAccountId',
			'bankAccount',
			'financialsEntityId',
			'cashAccountObjectId',
			'cashAccountObject',
			'pooledCashAccountObjectId',
			'pooledCashAccountObject',
			'interestAccountObjectId',
			'interestAccountObject',
			'updatedByUserId',
			'updatedByUser',
			'createdAt',
			'updatedAt',
		];
		super(object, properties);
	}
}
