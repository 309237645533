
import { DataType } from './dataType';
import { FinancialsVendor } from './financialsVendor';
import { State } from './state';
import { Country } from './country';
import { User } from './user';

export class FinancialsTaxFormPayeeName extends DataType {
	id: number;
	financialsVendorId: number;
	vendor: FinancialsVendor;
	name: string;
	name2: string;
	address1: string;
	city: string;
	zipCode: string;
	stateName: string;
	stateId: number;
    state: State;
	countryId: number;
    country: Country;
	updatedByUserId: number;
	updatedByUser: User;
	createdAt: string;
    updatedAt: string;

	constructor(object: Object = {}) {
		const properties = [
			'id',
			'financialsVendorId',
			'vendor',
			'name',
			'name2',
			'address1',
			'city',
			'zipCode',
			'stateName',
			'stateId',
			'state',
			'countryId',
			'country',
			'updatedByUserId',
			'updatedByUser',
			'createdAt',
			'updatedAt',];
		super(object, properties);
	}
}
