import { DataType } from './dataType';
import { User } from './user';

export class FinancialsFiscalYear extends DataType {
	id: number;
	fiscalYear: number;
	status: string;
	startDate: string;
	endDate: string;
	financialsEntityId: number;
	updatedByUserId: number;
	updatedByUser: User;
	createdAt: string;
	updatedAt: string;

	constructor(object: Object = {}) {
		const properties = [
			'id',
			'fiscalYear',
			'status',
			'startDate',
			'endDate',
			'financialsEntityId',
			'updatedByUserId',
			'updatedByUser',
			'createdAt',
			'updatedAt',
		];
		super(object, properties);
	}
}
