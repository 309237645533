import { DataType } from './dataType';
import { User } from './user';

export class UserPermission extends DataType {
	userRoleId: number;
	userId: number;
	user: User;
	roleId: number;
	application: string;
	applicationId: number;
	role: string;
	permissionGroup: string;
	permissionGroupId: number;
	permission: string;
	permissionId: number;

	constructor(object: Object = {}) {
		const properties = [
			'userRoleId',
			'userId',
			'user',
			'roleId',
			'application',
			'applicationId',
			'role',
			'permissionGroup',
			'permissionGroupId',
			'permission',
			'permissionId'
		];
		super(object, properties);
	}
}
