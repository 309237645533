import { DataType } from './dataType';
import { FinancialsDepartmentSeg } from './financialsDepartmentSeg';
import { FinancialsFunction } from './financialsFunction';
import { User } from './user';

export class FinancialsDivisionSeg extends DataType {
	id: number;
	divisionSegNumber: string;
	description: string;
	financialsEntityId: number;
	financialsDepartmentSegId: number;
	departmentSeg: FinancialsDepartmentSeg;
	financialsFunctionId: number;
	function: FinancialsFunction;
	status: string;
	updatedByUserId: number;
	updatedByUser: User;
	createdAt: string;
	updatedAt: string;

	constructor(object: Object = {}) {
		const properties = [
			'id',
			'divisionSegNumber',
			'description',
			'financialsEntityId',
			'financialsDepartmentSegId',
			'departmentSeg',
			'financialsFunctionId',
			'function',
			'status',
			'updatedByUserId',
			'updatedByUser',
			'createdAt',
			'updatedAt',
		];
		super(object, properties);
	}
}
