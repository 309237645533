import { DataType } from './dataType';
import { ReceivableAccountType } from './receivableAccountType';
import { ReceivableAccount } from './receivableAccount';
import { Customer } from './customer';

export class ReceivableAccountAgedSummary extends DataType {
  accountId: number;
  accountNumber: number;
  account: ReceivableAccount;
  accountTypeId: number;
  accountType: ReceivableAccountType;
  customerId: number;
  customerNumber: number;
  customer: Customer;
  status: string;
  name: string;
  accountBalance: number;
  currentAmount: number;
  thirtyDayAmount: number;
  sixtyDayAmount: number;
  ninetyDayAmount: number;
  onetwentyDayAmount: number;
  pastDueAmount: number;
  totalAmountDue: number;

  constructor(object: Object = {}) {
    const properties = [
      'accountId',
      'accountNumber',
      'account',
      'accountTypeId',
      'accountType',
      'customerId',
      'customerNumber',
      'customer',
      'status',
      'name',
      'accountBalance',
      'currentAmount',
      'thirtyDayAmount',
      'sixtyDayAmount',
      'ninetyDayAmount',
      'onetwentyDayAmount',
      'pastDueAmount',
      'totalAmountDue'
    ];
    super(object, properties);
  }
}
